import React, { useEffect, useState, useRef } from "react";
import "./LanguageSelector.scss";
import { useTranslation } from "react-i18next";
import { useDetectOutsideClick, useLocalStorage } from "common/js/hooks";
import { I18N, LANGUAGES, LOCALSTORAGE, DEFAULT_LANGUAGES } from "_constants";
import Globe from "common/img/GlobeIcon.svg";
import { getLang } from "common/js/i18n";
import _ from "lodash";

interface LangageSelectorProps {
  showAll: boolean;
}

const LangageSelector: React.FC<LangageSelectorProps> = ({ showAll }) => {
  const { i18n } = useTranslation(I18N.namespaces.marketing);
  const [currentLang, setCurrentLang] = useState(i18n.language);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useDetectOutsideClick(dropdownRef, false);
  const availableLanguages = showAll ? LANGUAGES : DEFAULT_LANGUAGES;

  const [langPref, setLangPref] = useLocalStorage<string | null>(
    LOCALSTORAGE.langPref,
    null
  );

  useEffect(() => {
    const langKeys = _.keys(availableLanguages);
    const preferredLang = langPref ? langPref : getLang();
    const lang = _.includes(langKeys, preferredLang)
      ? preferredLang
      : I18N.defaultLanguage;
    i18n.changeLanguage(lang);
    setCurrentLang(lang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [langPref]);

  const setPreferredLangage = (lang: string) => {
    setLangPref(lang);
    setOpen(false);
  };

  return (
    <div id="language-selector">
      <div
        id="language-selector--selected"
        className="btn btn-link"
        onClick={() => setOpen(!open)}
      >
        <img src={Globe} alt="globe icon" />
        {availableLanguages[currentLang]}
      </div>
      {open && (
        <div id="language-selector--dropdown" ref={dropdownRef}>
          {Object.keys(availableLanguages).map((l: string) => (
            <div
              key={l}
              onClick={() => setPreferredLangage(l)}
              className={`language-selector--dropdown-item ${
                l === currentLang ? "selected" : ""
              }`}
            >
              {availableLanguages[l]}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LangageSelector;
