import React, { useRef, RefObject, useState, useEffect } from "react";
import "./App.scss";
import { Route, Routes, useMatch } from "react-router-dom";
import { useResponsive } from "@farfetch/react-context-responsive";
import { ROUTES } from "_constants";
import Config from "config";
import { useTranslation } from "react-i18next";

// Components
import Footer from "common/js/components/Footer";
import Header from "common/js/components/Header";
import SideNav from "common/js/components/SideNav";

// Routes
import HomeRoute from "common/js/routes/Home";
import MeetingsRoute from "common/js/routes/Meetings";
import DemoRoute from "common/js/routes/Demo";
import LSUFAQRoute from "common/js/routes/LSUFAQ";
import DeleteAccountRoute from "common/js/routes/DeleteAccount";
import AndroidForegroundPermissionsRoute from "common/js/routes/AndroidForegroundPermissions";

const App: React.FC = () => {
  const topRef = useRef<HTMLDivElement>(null);
  const { mediaType, greaterThan } = useResponsive();
  const { i18n } = useTranslation();
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const demoRouteMatch = useMatch(ROUTES.demo);
  const lsuFaqRouteMatch = useMatch(ROUTES.faq);
  const androidForegroundPermissionsRouteMatch = useMatch(
    ROUTES.androidForegroundPermissions
  );
  const deleteAccountRouteMatch = useMatch(ROUTES.deleteAccount);

  useEffect(() => {
    if (greaterThan.sm && sideNavOpen) setSideNavOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaType]);

  const scrollToRef = (ref: RefObject<HTMLDivElement>) => {
    if (ref.current)
      window.scrollTo({
        top: ref.current.offsetTop,
        behavior: "smooth",
      });
  };

  const signIn = () => {
    if (sideNavOpen) setSideNavOpen(false);
    window.location.assign(Config.SIGNIN);
  };

  const joinMeeting = () => {
    if (sideNavOpen) setSideNavOpen(false);
    window.location.assign(Config.JOINMEETING);
  };

  const toggleSideNav = () => {
    setSideNavOpen(!sideNavOpen);
  };

  return (
    <div id="myveeva-marketing" className={mediaType} ref={topRef}>
      <SideNav
        open={sideNavOpen}
        signIn={signIn}
        joinMeeting={joinMeeting}
        setSideNavOpen={setSideNavOpen}
      />

      <Header
        toggleSideNav={toggleSideNav}
        scrollTop={() => scrollToRef(topRef)}
        signIn={signIn}
        joinMeeting={joinMeeting}
        logoNav={!lsuFaqRouteMatch && !demoRouteMatch}
        hideNav={
          !!lsuFaqRouteMatch ||
          !!demoRouteMatch ||
          !!deleteAccountRouteMatch ||
          !!androidForegroundPermissionsRouteMatch
        }
        hideLanguageSelector={
          !!demoRouteMatch ||
          !!deleteAccountRouteMatch ||
          !!androidForegroundPermissionsRouteMatch
        }
      />

      <Routes>
        <Route
          path={ROUTES.home}
          element={<HomeRoute scrollToRef={scrollToRef} signIn={signIn} />}
        />
        <Route path={ROUTES.meetings} element={<MeetingsRoute />} />
        <Route path={ROUTES.demo} element={<DemoRoute />} />
        <Route path={ROUTES.deleteAccount} element={<DeleteAccountRoute />} />
        <Route
          path={ROUTES.androidForegroundPermissions}
          element={<AndroidForegroundPermissionsRoute />}
        />
        <Route path={ROUTES.faq} element={<LSUFAQRoute />} />
        <Route
          path="*"
          element={
            <div id="not-found" className="content">
              Page Not Found
            </div>
          }
        />
      </Routes>

      {!demoRouteMatch && <Footer lang={i18n.language} />}
    </div>
  );
};

export default App;
