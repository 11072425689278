import { tLanguages } from "_types";
import _ from "lodash";

export const LANGUAGES: tLanguages = {
  bg: "Български",
  cs: "Česky",
  da: "Dansk",
  de: "Deutsch",
  el: "ελληνικά",
  en: "English",
  es: "Español",
  "es-MX": "Español (Mexico)",
  fi: "Suomi",
  fr: "Français",
  hu: "Magyar",
  id: "Bahasa Indonesia",
  it: "Italiano",
  ja: "日本語",
  ko: "한국어",
  nl: "Nederlands",
  pl: "Polski",
  "pt-BR": "Português (Brazil)",
  ro: "Română",
  ru: "Русский",
  sk: "Slovenčina",
  sv: "Svenska",
  th: "ไทย",
  tr: "Türkçe",
  uk: "Українська",
  vi: "Việtnam",
  "zh-CN": "中文",
  "zh-TW": "中文(台灣)",
};

export const I18N = {
  supportedLanguages: _.keys(LANGUAGES),
  defaultLanguage: "en",
  namespaces: {
    marketing: "marketing",
    lsu: "lsu",
  },
};

export const DEFAULT_LANGUAGES = _.pick(LANGUAGES, [
  "de",
  "en",
  "es",
  "fr",
  "it",
  "ja",
  "ko",
  "pt-BR",
]);

export const LOCALSTORAGE = {
  langPref: "veeva-engage-langPref",
};

export const ROUTES = {
  androidForegroundPermissions: "/android-foreground-permissions",
  deleteAccount: "/delete-account",
  demo: "/demo",
  faq: "/faq",
  home: "/",
  meetings: "/meetings",
};

export const BREAKPOINTS = {
  xs: "320px",
  sm: "480px",
  md: "768px",
  lg: "1280px",
  xl: "1800px",
};

export const BREAKPOINTS_MAX = {
  xs: "319px",
  sm: "479px",
  md: "767px",
  lg: "1279px",
  xl: "1799px",
};
