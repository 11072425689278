import React, { useState } from "react";
import "./Faq.scss";
import Chevron from "common/img/Chevron.svg";

interface FaqProps {
  question: string;
}

export const Faq: React.FC<FaqProps> = ({ question, children }) => {
  const [open, setOpen] = useState(false);

  return (
    <div
      className={`faq ${open ? "open" : "closed"}`}
      onClick={() => setOpen(!open)}
    >
      <div className="faq-question">
        {question}
        <img src={Chevron} alt="chevron" />
      </div>
      <div className="faq-answer">{children}</div>
    </div>
  );
};
