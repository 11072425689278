import React from "react";
import "./AndroidForegroundPermissions.scss";
import VeevaEngageForegroundPermissions from "common/video/VeevaEngageForegroundPermissions.mp4";

const AndroidForegroundPermissionsRoute: React.FC = () => {
  return (
    <div id="android-permissions-route" className="content">
      <div className="route-page-header">
        <div className="container">
          <h1 className="title">Android Foreground Permissions</h1>
        </div>
      </div>

      <div className="container">
        <video width="480" controls>
          <source src={VeevaEngageForegroundPermissions} type="video/mp4" />
          <source src={VeevaEngageForegroundPermissions} type="video/ogg" />
          Your browser does not support video.
        </video>
      </div>
    </div>
  );
};

export default AndroidForegroundPermissionsRoute;
