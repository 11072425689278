import React from "react";
import "./LSUFAQ.scss";
import Config from "config";
import { useTranslation } from "react-i18next";
import { I18N } from "_constants";
import { Faq } from "common/js/components/Faq";

//Images
import AppleStore from "common/img/AppleStore.svg";
import GooglePlay from "common/img/GooglePlay.svg";
import EngageAppIcon from "common/img/Engage_App_Icon.svg";
import EngageConnectAppIcon from "common/img/Engage_Connect_App_Icon.svg";
import EngageLogo from "common/img/Engage_Logo.svg";
import EngageConnectLogo from "common/img/Engage_Connect_Logo.svg";

const LSUFAQRoute: React.FC = () => {
  const { t } = useTranslation(I18N.namespaces.lsu);

  return (
    <div id="lsuFaq-route" className="content">
      <div className="lsuFaq-header">
        <div className="container">
          <h1 className="title">{t("pageTitle")}</h1>
          <p className="title-description">{t("pageDescription")}</p>
        </div>
      </div>
      <div className="lsuFaq-apps">
        <div className="container">
          <div>
            <div className="lsuFaq-app--logos">
              <img
                className="lsuFaq-app--icon"
                src={EngageAppIcon}
                alt="Engage App Icon"
              />
              <img
                className="lsuFaq-app--logo"
                src={EngageLogo}
                alt="Engage Logo"
              />
            </div>
            <div className="lsuFaq-app--text">{t("engage")}</div>
            <div className="lsuFaq-app--cta">
              <a href={Config.ENGAGE_APPLE_STORE}>
                <img src={AppleStore} alt="Download on the Apple Store" />
              </a>
              <a href={Config.ENGAGE_GOOGLE_PLAY}>
                <img src={GooglePlay} alt="Get it on Google Play" />
              </a>
            </div>
          </div>
          <div>
            <div className="lsuFaq-app--logos">
              <img
                className="lsuFaq-app--icon"
                src={EngageConnectAppIcon}
                alt="Engage Connect App Icon"
              />
              <img
                className="lsuFaq-app--logo"
                src={EngageConnectLogo}
                alt="Engage Connect Logo"
              />
            </div>
            <div className="lsuFaq-app--text">{t("engageConnect")}</div>
            <div className="lsuFaq-app--cta">
              <a href={Config.ENGAGE_CONNECT_APPLE_STORE}>
                <img src={AppleStore} alt="Download on the Apple Store" />
              </a>
              <a href={Config.ENGAGE_CONNECT_GOOGLE_PLAY}>
                <img src={GooglePlay} alt="Get it on Google Play" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="lsuFaq-faqs">
        <div className="container">
          <h2>{t("faq")}</h2>
          <div className="faqs">
            <div className="faq-section">
              <Faq question={t("q1")}>
                <p>{t("a1a")}</p>
                <p dangerouslySetInnerHTML={{ __html: t("a1b") }} />
                <p dangerouslySetInnerHTML={{ __html: t("a1c") }} />
                <p dangerouslySetInnerHTML={{ __html: t("a1d") }} />
                <p>{t("a1e")}</p>
              </Faq>
              <Faq question={t("q2")}>
                <p>{t("a2")}</p>
              </Faq>
              <Faq question={t("q3")}>
                <p dangerouslySetInnerHTML={{ __html: t("a3") }} />
              </Faq>
              <Faq question={t("q4")}>
                <p dangerouslySetInnerHTML={{ __html: t("a4") }} />
              </Faq>
              <Faq question={t("q5")}>
                <p>{t("a5")}</p>
              </Faq>
              <Faq question={t("q6")}>
                <p>{t("a6")}</p>
              </Faq>
              <Faq question={t("q7")}>
                <p>{t("a7")}</p>
              </Faq>
              <Faq question={t("q8")}>
                <p>{t("a8")}</p>
              </Faq>
              <Faq question={t("q9")}>
                <p>{t("a9")}</p>
              </Faq>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LSUFAQRoute;
