import React from "react";
import "./DeleteAccount.scss";

const DeleteAccountRoute: React.FC = () => {
  return (
    <div id="delete-account-route" className="content">
      <div className="route-page-header">
        <div className="container">
          <h1 className="title">How to Delete Your Veeva Engage Account</h1>
        </div>
      </div>

      <div className="container">
        <ol type="1">
          <li>
            Sign into your Veeva Engage account:{" "}
            <a
              target="_blank"
              href="https://app.veevaengage.com/web/login"
              rel="noreferrer"
            >
              https://app.veevaengage.com/web/login
            </a>
          </li>
          <li>
            Select “Settings” from your account drop-down menu in the header
            bar. Alternatively, click on this link:{" "}
            <a
              target="_blank"
              href="https://app.veevaengage.com/web/settings"
              rel="noreferrer"
            >
              https://app.veevaengage.com/web/settings
            </a>
          </li>
          <li>Click Delete My Account</li>
          <li>
            Enter your email address in the field to confirm and click Delete My
            Account button
          </li>
          <li>
            You will be immediately signed out, and your account will be fully
            anonymized. This will include your personal data and messages
            exchanged between you and other users.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default DeleteAccountRoute;
