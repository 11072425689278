import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import initI18n from "./common/js/i18n";
import "./common/css/marketing.scss";
import { BREAKPOINTS, BREAKPOINTS_MAX } from "./_constants";
import { ResponsiveProvider } from "@farfetch/react-context-responsive";
import ScrollToTop from "common/js/components/ScrollToTop";

function init() {
  const wrapper = document.getElementById("root");

  ReactDOM.render(
    <React.StrictMode>
      <ResponsiveProvider
        breakpoints={BREAKPOINTS}
        breakpointsMax={BREAKPOINTS_MAX}
        mobileBreakpoint="sm"
      >
        <BrowserRouter>
          <ScrollToTop />
          <App />
        </BrowserRouter>
      </ResponsiveProvider>
    </React.StrictMode>,
    wrapper
  );
}

initI18n().then(init);
