import React, { useEffect, useState } from "react";
import "./Footer.scss";
import VeevaLogo from "common/img/Veeva_logo.svg";
import { useTranslation } from "react-i18next";
import { I18N } from "_constants";

interface FooterProps {
  lang: string;
}

const Footer: React.FC<FooterProps> = ({ lang }) => {
  const { t } = useTranslation(I18N.namespaces.marketing);
  const { origin } = window.location;

  const [privacyUrl, setPrivacyUrl] = useState(`${origin}/legal/privacy`);
  const [termsUrl, setTermsUrl] = useState(`${origin}/legal/terms`);
  const [ccpaUrl, setCcpaUrl] = useState(`${origin}/legal/data`);

  useEffect(() => {
    const currentLang = lang !== I18N.defaultLanguage ? "/" + lang : "";
    setPrivacyUrl(`${origin}/legal${currentLang}/privacy`);
    setTermsUrl(`${origin}/legal${currentLang}/terms`);
    setCcpaUrl(`${origin}/legal${currentLang}/data`);
  }, [lang, origin]);

  return (
    <footer id="footer">
      <div className="container">
        <div>
          <img src={VeevaLogo} alt="Veeva Logo" />
          <div className="copyright">
            {t("footer.copyright", { year: new Date().getFullYear() })}
          </div>
          <div className="links">
            <a href={ccpaUrl} target="_blank" rel="noreferrer">
              {t("footer.doNotSellMyPersonalInfo")}
            </a>
            <a href={privacyUrl} target="_blank" rel="noreferrer">
              {t("footer.privacyStatement")}
            </a>
            <a href={termsUrl} target="_blank" rel="noreferrer">
              {t("footer.terms")}
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
