import React, { useState, useEffect } from "react";
import "./Meetings.scss";
import Config from "config";
import { useTranslation } from "react-i18next";
import { I18N } from "_constants";
import { Faq } from "common/js/components/Faq";
import { tImgObject } from "_types";

//Images
import AppleLogo from "common/img/AppleLogo.svg";
import AndroidLogo from "common/img/AndroidLogo.svg";
import WindowsLogo from "common/img/WindowsLogo.svg";
import iOSLogo from "common/img/iOSLogo.svg";
import BrowserCompat from "common/img/Browser-compatibility.png";
import PermissionsSettings from "common/img/Permissions-settings.png";

//SiteSettings
import SiteSettingsEN from "common/img/site_settings_en.png";
import SiteSettingsJA from "common/img/site_settings_ja.png";

const SiteSettings = {
  en: SiteSettingsEN,
  ja: SiteSettingsJA,
};

const MeetingsRoute: React.FC = () => {
  const { t, i18n } = useTranslation(I18N.namespaces.marketing);

  const [siteSettingsImg, setSiteSettingsImg] = useState(SiteSettingsEN);

  useEffect(() => {
    const lang = i18n.language === "ja" ? i18n.language : "en";
    setSiteSettingsImg((SiteSettings as tImgObject)[lang]);
  }, [i18n.language]);

  return (
    <div id="meetings-route" className="content">
      <div className="route-page-header">
        <div className="container">
          <h1 className="title">{t("helpCenter.title")}</h1>
          <p className="title-description">{t("helpCenter.body")}</p>
        </div>
      </div>
      <div className="support-quick-starts">
        <div className="container">
          <div>
            <div className="support-quick-start--logo">
              <img src={iOSLogo} alt="ios logo" />
            </div>
            <div className="support-quick-start--text">
              {t("quickStartguide.ios")}
            </div>
            <a
              className="support-quick-start--cta"
              target="_blank"
              rel="noreferrer"
              href={`${Config.QUICK_START}/${i18n.language}/EngageMeetingQSG's-iOS.pdf`}
            >
              {t("quickStartguide.cta")} ▸
            </a>
          </div>
          <div>
            <div className="support-quick-start--logo">
              <img src={AndroidLogo} alt="android logo" />
            </div>
            <div className="support-quick-start--text">
              {t("quickStartguide.android")}
            </div>
            <a
              className="support-quick-start--cta"
              target="_blank"
              rel="noreferrer"
              href={`${Config.QUICK_START}/${i18n.language}/EngageMeetingQSG's-Android.pdf`}
            >
              {t("quickStartguide.cta")} ▸
            </a>
          </div>
          <div>
            <div className="support-quick-start--logo">
              <img src={AppleLogo} alt="apple logo" />
            </div>
            <div className="support-quick-start--text">
              {t("quickStartguide.apple")}
            </div>
            <a
              className="support-quick-start--cta"
              target="_blank"
              rel="noreferrer"
              href={`${Config.QUICK_START}/${i18n.language}/EngageMeetingQSG's-Mac.pdf`}
            >
              {t("quickStartguide.cta")} ▸
            </a>
          </div>
          <div>
            <div className="support-quick-start--logo">
              <img src={WindowsLogo} alt="windows logo" />
            </div>
            <div className="support-quick-start--text">
              {t("quickStartguide.windows")}
            </div>
            <a
              className="support-quick-start--cta"
              target="_blank"
              rel="noreferrer"
              href={`${Config.QUICK_START}/${i18n.language}/EngageMeetingQSG's-Windows.pdf`}
            >
              {t("quickStartguide.cta")} ▸
            </a>
          </div>
        </div>
      </div>
      <div className="support-faqs">
        <div className="container">
          <h2>{t("faq")}</h2>
          <div className="faqs">
            <div className="faq-section">
              <div className="faq-title">{t("faqSection1.title")}</div>

              <Faq question={t("faqSection1.q1")}>
                <p>{t("faqSection1.a1")}</p>
              </Faq>
              <Faq question={t("faqSection1.q2")}>
                <p dangerouslySetInnerHTML={{ __html: t("faqSection1.a2") }} />
              </Faq>
              <Faq question={t("faqSection1.q3")}>
                <p dangerouslySetInnerHTML={{ __html: t("faqSection1.a3") }} />
              </Faq>
              <Faq question={t("faqSection1.q4")}>
                <p>{t("faqSection1.a4a")}</p>
                <p>{t("faqSection1.a4b")}</p>
                <div className="faq-img">
                  <img src={BrowserCompat} alt="browser compatibility" />
                </div>
              </Faq>
              <Faq question={t("faqSection1.q5")}>
                <p>{t("faqSection1.a5")}</p>
              </Faq>
              <Faq question={t("faqSection1.q6")}>
                <p>{t("faqSection1.a6")}</p>
              </Faq>
              <Faq question={t("faqSection1.q7")}>
                <p>{t("faqSection1.a7")}</p>
              </Faq>
            </div>

            <div className="faq-section">
              <div className="faq-title">{t("faqSection2.title")}</div>

              <Faq question={t("faqSection2.q1")}>
                <p>{t("faqSection2.a1")}</p>
              </Faq>
              <Faq question={t("faqSection2.q2")}>
                <p>{t("faqSection2.a2a")}</p>
                <div className="faq-img">
                  <img src={siteSettingsImg} alt="site settings" />
                </div>
                <div className="faq-img">
                  <img src={PermissionsSettings} alt="permissions settings" />
                </div>
                <p>{t("faqSection2.a2b")}</p>
              </Faq>
              <Faq question={t("faqSection2.q3")}>
                <p>{t("faqSection2.a3")}</p>
              </Faq>
              <Faq question={t("faqSection2.q4")}>
                <p>{t("faqSection2.a4")}</p>
              </Faq>
              <Faq question={t("faqSection2.q5")}>
                <p>{t("faqSection2.a5")}</p>
              </Faq>
              <Faq question={t("faqSection2.q6")}>
                <p>{t("faqSection2.a6")}</p>
              </Faq>
              <Faq question={t("faqSection2.q7")}>
                <p>{t("faqSection2.a7")}</p>
              </Faq>
            </div>

            <div className="faq-section">
              <div className="faq-title">{t("faqSection3.title")}</div>

              <Faq question={t("faqSection3.q1")}>
                <p>{t("faqSection3.a1")}</p>
              </Faq>
              <Faq question={t("faqSection3.q2")}>
                <p>{t("faqSection3.a2")}</p>
                <ul>
                  <li>{t("faqSection3.a2bull1")}</li>
                  <li>{t("faqSection3.a2bull2")}</li>
                  <li>{t("faqSection3.a2bull3")}</li>
                  <li>{t("faqSection3.a2bull4")}</li>
                </ul>
              </Faq>
              <Faq question={t("faqSection3.q3")}>
                <p>
                  <a
                    className="support-quick-start--cta"
                    target="_blank"
                    rel="noreferrer"
                    href={`${Config.QUICK_START}/${i18n.language}/EngageSignUpGuide.pdf`}
                  >
                    {t("faqSection3.a3")}
                  </a>
                </p>
                <ul>
                  <li>{t("faqSection3.a3bull1")}</li>
                  <li>{t("faqSection3.a3bull2")}</li>
                  <li>{t("faqSection3.a3bull3")}</li>
                </ul>
              </Faq>
              <Faq question={t("faqSection3.q4")}>
                <p>{t("faqSection3.a4")}</p>
              </Faq>
            </div>

            <div className="faq-section">
              <div className="faq-title">{t("faqSection4.title")}</div>

              <Faq question={t("faqSection4.q1")}>
                <p dangerouslySetInnerHTML={{ __html: t("faqSection4.a1") }} />
              </Faq>
              <Faq question={t("faqSection4.q2")}>
                <p>{t("faqSection4.a2a")}</p>
                <p>{t("faqSection4.a2b")}</p>
              </Faq>
              <Faq question={t("faqSection4.q3")}>
                <p>{t("faqSection4.a3")}</p>
              </Faq>
            </div>
          </div>
        </div>
      </div>
      <div className="support-email">
        <div className="container">
          <div className="headline">{t("haveAnotherQuestion.title")}</div>
          <a href="mailto:support@veevaengage.com" className="btn btn-lg">
            {t("haveAnotherQuestion.cta")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default MeetingsRoute;
