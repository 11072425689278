import React from "react";
import "./SideNav.scss";
import { I18N, ROUTES } from "_constants";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

interface SideNavProps {
  open?: boolean;
  signIn: () => void;
  joinMeeting: () => void;
  setSideNavOpen: (isOpen: boolean) => void;
}

const SideNav: React.FC<SideNavProps> = ({
  open = false,
  signIn,
  joinMeeting,
  setSideNavOpen,
}) => {
  const { t } = useTranslation(I18N.namespaces.marketing);

  return (
    <aside id="side-nav" className={` ${open ? "open" : "closed"}`}>
      <NavLink to={ROUTES.meetings} onClick={() => setSideNavOpen(false)}>
        {t("support")}
      </NavLink>
      <button onClick={joinMeeting}>{t("joinMeeting")}</button>
      <button onClick={signIn}>{t("signIn")}</button>
    </aside>
  );
};

export default SideNav;
