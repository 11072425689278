import React from "react";
import "./Header.scss";
import EngageLogo from "common/img/Engage_Logo_Reverse.svg";
import { useTranslation } from "react-i18next";
import { I18N, ROUTES } from "_constants";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import LangageSelector from "../LanguageSelector";
import { useResponsive } from "@farfetch/react-context-responsive";
import HamburgerMenu from "common/img/HamburgerMenu.svg";

interface HeaderProps {
  scrollTop: () => void;
  signIn: () => void;
  joinMeeting: () => void;
  toggleSideNav: () => void;
  hideNav?: boolean;
  logoNav?: boolean;
  hideLanguageSelector?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  scrollTop,
  signIn,
  joinMeeting,
  toggleSideNav,
  hideNav = false,
  logoNav = true,
  hideLanguageSelector = false,
}) => {
  const { t } = useTranslation(I18N.namespaces.marketing);
  const { lessThan, greaterThan } = useResponsive();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const onClickLogo = () => {
    if (pathname === ROUTES.home) {
      scrollTop();
    } else {
      navigate(ROUTES.home);
    }
  };

  return (
    <header id="header">
      <div className="container">
        <div className="header-nav">
          {lessThan.md && !hideNav && (
            <div className="header-nav--toggle" onClick={toggleSideNav}>
              <img src={HamburgerMenu} alt="toggle menu" />
            </div>
          )}

          {logoNav ? (
            <button className="btn-clear" onClick={onClickLogo}>
              <img className="engage-logo" src={EngageLogo} alt="Engage Logo" />
            </button>
          ) : (
            <img className="engage-logo" src={EngageLogo} alt="Engage Logo" />
          )}
        </div>

        <div className="cta">
          {!hideLanguageSelector && <LangageSelector showAll={hideNav} />}

          {greaterThan.sm && !hideNav && (
            <>
              {pathname !== ROUTES.meetings && (
                <NavLink className="btn btn-link" to={ROUTES.meetings}>
                  {t("support")}
                </NavLink>
              )}
              <button className="btn btn-sm btn-blue" onClick={joinMeeting}>
                {t("joinMeeting")}
              </button>
              <button className="btn btn-sm btn-orange" onClick={signIn}>
                {t("signIn")}
              </button>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
