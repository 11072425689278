import bg from "./bg/lsu.json";
import cs from "./cs/lsu.json";
import da from "./da/lsu.json";
import de from "./de/lsu.json";
import el from "./el/lsu.json";
import en from "./en/lsu.json";
import es from "./es/lsu.json";
import esMX from "./es-MX/lsu.json";
import fi from "./fi/lsu.json";
import fr from "./fr/lsu.json";
import hu from "./hu/lsu.json";
import id from "./id/lsu.json";
import it from "./it/lsu.json";
import ja from "./ja/lsu.json";
import ko from "./ko/lsu.json";
import nl from "./nl/lsu.json";
import pl from "./pl/lsu.json";
import ptBR from "./pt-BR/lsu.json";
import ro from "./ro/lsu.json";
import ru from "./ru/lsu.json";
import sk from "./sk/lsu.json";
import sv from "./sv/lsu.json";
import th from "./th/lsu.json";
import tr from "./tr/lsu.json";
import uk from "./uk/lsu.json";
import vi from "./vi/lsu.json";
import zhCN from "./zh-CN/lsu.json";
import zhTW from "./zh-TW/lsu.json";

const lsu = {
  bg,
  cs,
  da,
  de,
  el,
  en,
  es,
  "es-MX": esMX,
  fi,
  fr,
  hu,
  id,
  it,
  ja,
  ko,
  nl,
  pl,
  "pt-BR": ptBR,
  ro,
  ru,
  sk,
  sv,
  th,
  tr,
  uk,
  vi,
  "zh-CN": zhCN,
  "zh-TW": zhTW,
};

export default lsu;
