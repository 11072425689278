import React from "react";
import "./Demo.scss";
import Vimeo from "@u-wave/react-vimeo";

const DemoRoute: React.FC = () => {
  return (
    <div id="demo-route" className="content">
      <div className="container">
        <Vimeo video="675680312" responsive={true} />
      </div>
      <p className="video-trouble">
        Trouble viewing video? Please access <a href="https://vimeo.com/675680312">here</a>.
      </p>
    </div>
  );
};

export default DemoRoute;
