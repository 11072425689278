import i18n, { TFunction } from "i18next";
import { initReactI18next } from "react-i18next";
import { I18N, LOCALSTORAGE } from "../../_constants";
import i18nMarketing from "../../i18n/marketing";
import i18nLSU from "../../i18n/lsu";
import _ from "lodash";

export function getLang(): string {
  const preferredLang = localStorage.getItem(LOCALSTORAGE.langPref);
  if (preferredLang) return preferredLang;

  const languages: string[] = [];
  navigator.languages.forEach((lang) => {
    // Add language and also add country-code-less variant if present (eg. es-CO -> [es-CO, es])
    languages.push(lang);
    const match = /([a-zA-Z][a-zA-Z])-([a-zA-Z][a-zA-Z])/.exec(lang);
    if (match) {
      languages.push(match[1]);
    }
  });

  const found = languages.find((s) => I18N.supportedLanguages.includes(s));
  return found || I18N.defaultLanguage;
}

function initI18n(): Promise<TFunction> {
  const resources = _.merge(i18nMarketing, i18nLSU);

  return i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      ns: [I18N.namespaces.lsu, I18N.namespaces.marketing],
      defaultNS: I18N.namespaces.marketing,
      resources,
      lng: getLang(),
      fallbackLng: I18N.defaultLanguage,
      interpolation: {
        escapeValue: false,
        prefix: "{",
        suffix: "}",
      },
    });
}

export default initI18n;
