import de from "./de/marketing.json";
import en from "./en/marketing.json";
import es from "./es/marketing.json";
import fr from "./fr/marketing.json";
import it from "./it/marketing.json";
import ja from "./ja/marketing.json";
import ko from "./ko/marketing.json";
import ptBR from "./pt-BR/marketing.json";

const marketing = { de, en, es, fr, it, ja, ko, "pt-BR": ptBR };

export default marketing;
