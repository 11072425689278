/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { RefObject, useRef, useEffect, useState } from "react";
import "./Home.scss";
import Config from "config";
import { useTranslation } from "react-i18next";
import { I18N } from "_constants";

// Images
import AppleStore from "common/img/AppleStore.svg";
import GooglePlay from "common/img/GooglePlay.svg";
import ChatIcon from "common/img/Icon_Chat.svg";
import DocumentIcon from "common/img/Icon_Document.svg";
import MeetIcon from "common/img/Icon_Meet.svg";
import HeroGeneric from "common/img/Hero.png";

//Video
import HeroVideo from "common/video/HCP_Website_Animation.mp4";

//Engage
import EngageDE from "common/img/web_screen_engage_de.png";
import EngageEN from "common/img/web_screen_engage_en.png";
import EngageES from "common/img/web_screen_engage_es.png";
import EngageFR from "common/img/web_screen_engage_fr.png";
import EngageIT from "common/img/web_screen_engage_it.png";
import EngageJA from "common/img/web_screen_engage_ja.png";
import EngageKO from "common/img/web_screen_engage_ko.png";
import EngagePTBR from "common/img/web_screen_engage_ptBR.png";

//Chat
import ChatDE from "common/img/web_screen_chat_de.png";
import ChatEN from "common/img/web_screen_chat_en.png";
import ChatES from "common/img/web_screen_chat_es.png";
import ChatFR from "common/img/web_screen_chat_fr.png";
import ChatIT from "common/img/web_screen_chat_it.png";
import ChatJA from "common/img/web_screen_chat_ja.png";
import ChatKO from "common/img/web_screen_chat_ko.png";
import ChatPTBR from "common/img/web_screen_chat_ptBR.png";

//Meetings
import MeetingsDE from "common/img/web_screen_meetings_de.png";
import MeetingsEN from "common/img/web_screen_meetings_en.png";
import MeetingsES from "common/img/web_screen_meetings_es.png";
import MeetingsFR from "common/img/web_screen_meetings_fr.png";
import MeetingsIT from "common/img/web_screen_meetings_it.png";
import MeetingsJA from "common/img/web_screen_meetings_ja.png";
import MeetingsKO from "common/img/web_screen_meetings_ko.png";
import MeetingsPTBR from "common/img/web_screen_meetings_ptBR.png";

//Laptop
import LaptopDE from "common/img/laptop_de.png";
import LaptopEN from "common/img/laptop_en.png";
import LaptopES from "common/img/laptop_es.png";
import LaptopFR from "common/img/laptop_fr.png";
import LaptopIT from "common/img/laptop_it.png";
import LaptopJA from "common/img/laptop_ja.png";
import LaptopKO from "common/img/laptop_ko.png";
import LaptopPTBR from "common/img/laptop_ptBR.png";

const MyEngageVideo = {
  en: "https://fast.wistia.net/embed/iframe/j42bvyp2cm",
  es: "https://fast.wistia.net/embed/iframe/3bzsv870hg",
  "pt-BR": "https://fast.wistia.net/embed/iframe/rphflg9cib",
  ja: "https://fast.wistia.net/embed/iframe/tacgvtie6c",
};

const Engage = {
  de: EngageDE,
  en: EngageEN,
  es: EngageES,
  fr: EngageFR,
  it: EngageIT,
  ja: EngageJA,
  ko: EngageKO,
  "pt-BR": EngagePTBR,
};

const Chat = {
  de: ChatDE,
  en: ChatEN,
  es: ChatES,
  fr: ChatFR,
  it: ChatIT,
  ja: ChatJA,
  ko: ChatKO,
  "pt-BR": ChatPTBR,
};

const Meetings = {
  de: MeetingsDE,
  en: MeetingsEN,
  es: MeetingsES,
  fr: MeetingsFR,
  it: MeetingsIT,
  ja: MeetingsJA,
  ko: MeetingsKO,
  "pt-BR": MeetingsPTBR,
};

const Laptop = {
  de: LaptopDE,
  en: LaptopEN,
  es: LaptopES,
  fr: LaptopFR,
  it: LaptopIT,
  ja: LaptopJA,
  ko: LaptopKO,
  "pt-BR": LaptopPTBR,
};

type tAssetObject = {
  [lang: string]: string;
};

interface HomeRouteProps {
  scrollToRef: (ref: RefObject<HTMLDivElement>) => void;
  signIn: () => void;
}

const HomeRoute: React.FC<HomeRouteProps> = ({ scrollToRef, signIn }) => {
  const { t, i18n } = useTranslation(I18N.namespaces.marketing);
  const howItWorksRef = useRef<HTMLDivElement>(null);
  const [engageImg, setEngageImg] = useState(EngageEN);
  const [chatImg, setsChatImg] = useState(ChatEN);
  const [meetingsImg, setMeetingsImg] = useState(MeetingsEN);
  const [laptopImg, setLaptopImg] = useState(LaptopEN);
  const [myEngageVideo, setMyEngageVideo] = useState<string>(
    "https://fast.wistia.net/embed/iframe/j42bvyp2cm"
  );

  useEffect(() => {
    const lang = i18n.language;
    setEngageImg((Engage as tAssetObject)[lang]);
    setsChatImg((Chat as tAssetObject)[lang]);
    setMeetingsImg((Meetings as tAssetObject)[lang]);
    setLaptopImg((Laptop as tAssetObject)[lang]);
    resetVideo(i18n.language);
  }, [i18n.language]);

  const resetVideo = (lang: string) => {
    const video =
      (MyEngageVideo as tAssetObject)[lang] ||
      (MyEngageVideo as tAssetObject)["en"];
    setTimeout(() => {
      setMyEngageVideo(video);
    }, 0);
  };

  return (
    <div id="home-route" className="content">
      <div className="hero">
        <div className="container">
          <div
            className={`hero-body ${
              i18n.language === "en" ? "" : "with-placeholder"
            }`}
          >
            <div
              className="headline"
              dangerouslySetInnerHTML={{
                __html: t("hero.title"),
              }}
            />

            <div className="bullets">
              <div className="bullet bullet1">
                <span className="bullet-icon">
                  <img src={DocumentIcon} alt="" />
                </span>
                <span
                  className="bullet-text"
                  dangerouslySetInnerHTML={{
                    __html: t("hero.bullet1"),
                  }}
                />
              </div>
              <div className="bullet bullet2">
                <span className="bullet-icon">
                  <img src={ChatIcon} alt="" />
                </span>
                <span
                  className="bullet-text"
                  dangerouslySetInnerHTML={{
                    __html: t("hero.bullet2"),
                  }}
                />
              </div>
              <div className="bullet bullet3">
                <span className="bullet-icon">
                  <img src={MeetIcon} alt="" />
                </span>
                <span
                  className="bullet-text"
                  dangerouslySetInnerHTML={{
                    __html: t("hero.bullet3"),
                  }}
                />
              </div>
            </div>

            <div className="hero-actions">
              <button className="btn btn-lg btn-orange" onClick={signIn}>
                {t("signUpToday")}
              </button>

              <button
                className="btn btn-lg"
                onClick={() => scrollToRef(howItWorksRef)}
              >
                {t("howItWorks.title")}
              </button>
            </div>
          </div>

          {i18n.language === "en" && (
            <div className="hero-video">
              <video width="640" playsInline autoPlay muted loop>
                <source src={HeroVideo} type="video/mp4" />
                <source src={HeroVideo} type="video/ogg" />
                Your browser does not support video.
              </video>
            </div>
          )}

          {i18n.language !== "en" && (
            <div className="hero-video--placeholder">
              <img src={HeroGeneric} alt="hero video placeholder" />
            </div>
          )}
        </div>
      </div>

      <div className="content-body container">
        <div className="engage-with-pharma">
          <img src={engageImg} alt={t("engageWithPharma.title")} />
          <div>
            <div className="headline">{t("engageWithPharma.title")}</div>
            <div className="content-body--text">
              {t("engageWithPharma.body")}
            </div>
          </div>
        </div>

        <div className="chat-between-meetings">
          <div>
            <div className="headline">{t("chatBetweenMeetings.title")}</div>
            <div className="content-body--text">
              {t("chatBetweenMeetings.body")}
            </div>
          </div>
          <img src={chatImg} alt={t("chatBetweenMeetings.title")} />
        </div>

        <div className="join-mtgs-faster">
          <img src={meetingsImg} alt={t("joinMeetingsFaster.title")} />
          <div>
            <div className="headline">{t("joinMeetingsFaster.title")}</div>
            <div className="content-body--text">
              {t("joinMeetingsFaster.body")}
            </div>
          </div>
        </div>
      </div>

      <div className="how-it-works" id="how-it-works" ref={howItWorksRef}>
        <div className="container">
          <div className="headline">{t("howItWorks.title")}</div>
          <div className="how-it-works--body">{t("howItWorks.body")}</div>
          <div className="my-engage-video-wrapper">
              <iframe 
                src={myEngageVideo}
                width="848" 
                height="478" 
                title={t("howItWorks.title")}
                allowFullScreen 
                className="wistia_embed">
                 Your browser does not support video.
              </iframe>
          </div>
        </div>
      </div>

      <div className="get-started">
        <div className="container">
          <div>
            <div className="headline">{t("getStartedWithEngage.title")}</div>
            <div
              className="get-started--body"
              dangerouslySetInnerHTML={{
                __html: t("getStartedWithEngage.body"),
              }}
            />
            <div className="get-started--actions">
              <button className="btn btn-md" onClick={signIn}>
                {t("signUpToday")}
              </button>
              <div>
                <a href={Config.ENGAGE_APPLE_STORE}>
                  <img src={AppleStore} alt="Download on the Apple Store" />
                </a>
                <a href={Config.ENGAGE_GOOGLE_PLAY}>
                  <img src={GooglePlay} alt="Get it on Google Play" />
                </a>
              </div>
            </div>
          </div>

          <img className="get-started--laptop" src={laptopImg} alt="laptop" />
        </div>
      </div>
    </div>
  );
};

export default HomeRoute;
